import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, Stack } from "react-bootstrap";
import LogoWhite from "../../assets/images/logo-4Rivers-white.webp";
import iconFacebook from "../../assets/images/icons/iconFacebook.svg";

import { AppContext } from "src/context/app_context";
import useUtilityService from "src/service/utility_service";

import "./footer.scss";

const Footer = () => {

    const { organization, isLoading, error } = useContext(AppContext);
    const { sortOfficeHours } = useUtilityService();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error loading organization data.</div>;
    }
    if (!organization) {
        return null;
    }

    const officeHours = organization.office_hours;
    const physicalAddress = organization.physical_address;

    // Sort the office hours using the utility function
    const sortedOfficeHours = officeHours ? sortOfficeHours(officeHours) : [];

    let copyrightYear = new Date().getFullYear();

    return (
        <Container fluid className="mt-auto footer-container">
            <Container className="pt-4 pt-lg-5">
                <Row>
                    <Col xs="auto" sm={2} lg="auto">
                        <Link to="/" className="footer-logo" style={{ textDecoration: "none", color: "#ffffff" }}>
                            <img className="footer-logo-image" src={LogoWhite} alt={"logo"} />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} lg={3} className="mt-1 footer-logo-text">
                        <Stack gap={0} className="mt-2">
                            <div>{physicalAddress.address_line_one}</div>
                            <div >{physicalAddress.city}, {physicalAddress.state} {physicalAddress.postal_code}</div>
                            <div><a href={`tel: ${organization.main_phone_nbr}`}>{organization.main_phone_nbr}</a></div>
                            <div><a href={`mailto: ${organization.display_email}`}>{organization.display_email}</a></div>
                            <div className="py-2"><a href="https://www.facebook.com/4riversvet/" target="_blank" rel="noreferrer"><img src={iconFacebook} alt="facebook" /></a></div>
                        </Stack>
                    </Col>
                    <Col sm={{ span: 10, offset: 2 }} lg={{ span: 5, offset: 0 }} className="footer-office-hours">
                        <div className="footer-section-title">Office Hours</div>
                        {sortedOfficeHours.map(({ day, hours }) => (
                            <Row key={day}>
                                <Col lg={5} className="footer-section-days">{day}</Col>
                                <Col>{hours}</Col>
                            </Row>
                        ))}
                    </Col>
                    <Col sm={{ span: 10, offset: 2 }} lg={{ span: 2, offset: 0 }}>
                        <div className="footer-section-title">Navigate</div>
                        <Stack gap={3}>
                            <div><Link to="/services">Services</Link></div>
                            <div><Link to="/about-us">About Us</Link></div>
                            <div><Link to="/contact-us">Contact Us</Link></div>
                            <div><a href={organization.online_pharmacy_url} target="_blank" rel="noopener noreferrer">Online Pharmacy</a></div>
                            <div><Link to="/faqs">FAQs</Link></div>
                        </Stack>
                    </Col>
                </Row>
            </Container>
            <Container className="pb-4">
                <hr className="divider" />
                <div className="footer-copyright-line-container">
                    <span className="footer-copyright-line">© {copyrightYear}&nbsp;&nbsp;{organization.display_name}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <span className="footer-copyright-line">This is an <a href="https://everydvm.com" rel="noreferrer">EveryDVM</a> website.</span>
                </div>
            </Container>
        </Container>
    );
};

export default Footer;