import React from "react";
import { Container } from "react-bootstrap";

import MainHero from "../main/main_hero";
import EveryCircle from "../global/every_circle";

import "../global/scss/every_subhero.scss";

const AboutUs = () => {

    return (
        <>
            <MainHero
                title="About Us"
                subtitle="Meet the team"
                text={<>
                    The professional and courteous staff at Four Rivers Veterinary seeks to provide the best possible medical care, surgical care and dental care for their highly-valued patients.
                    We are committed to promoting responsible pet ownership, preventive health care and health-related educational opportunities for our clients. Four Rivers Veterinary strives to offer excellence in veterinary care to Ontario, OR and surrounding areas.
                </>}
            />

            <div className="spacer wave-top"></div>

            <Container className="max-width-xl">
                <EveryCircle data="team" />
            </Container>
        </>
    );
};

export default AboutUs;