import React, { useContext, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";


import snackbarType from "../../enum/snackbar_type";
import templateType from "../../enum/email_template_type";

import maskPhoneOnChange from "../../util/maskPhoneOnChange";

import FormsSchema from "../../schema/forms";

import useMessageService from "../../service/message_service";

import PageLead from "../global/page_lead";

import { AppContext } from "src/context/app_context";

import "./scss/forms.scss";

const RequestAppointment = (props) => {
    let { showSnack } = props;
    const recaptchaRef = useRef();
    const messageService = useMessageService();

    const { organization } = useContext(AppContext);

    const locationRef = useRef();

    const { requestAppointmentForm } = FormsSchema;

    const {
        clearErrors,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(requestAppointmentForm)
    });

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const phoneField = useRef();
    const phoneMobileField = useRef();
    const phoneOtherField = useRef();

    const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const onPhoneChange = async (input) => {
        let { value } = input.target;
        maskPhoneOnChange(input, clearErrors);
        setValue(input.target.name, value.replace(/\D/g, ""));
    };

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            ownerName: data.full_name,
            ownerEmail: data.email,
            ownerPhone: data.phone,
            petName: data.pet_name,
            petSpecies: data.pet_species,
            petBreed: data.pet_breed,
            appointmentType: data.appointment_type,
            message: data.message,
            attachments: data.attachments,
            preferredDay: data.preferred_day,
            preferredTime: data.preferred_time,
            templateName: templateType.REQUEST_APPOINTMENT,
            recaptchaToken
        };

        let res = await messageService.sendMessage(messageData);
        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, "Your message has been sent!");
        }
        
        reset();
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            if (errors.full_name || errors.email) {
                return;
            }
            if (errors.phone_other) {
                phoneOtherField.current.focus();
                return;
            }
            if (errors.phone_mobile) {
                phoneMobileField.current.focus();
                return;
            }
            if (errors.phone) {
                phoneField.current.focus();
                return;
            }
            if (errors.address_line_one) {
                locationRef.current.focus();
            }
        }
        
    });

    return organization && (
        <Container>
            <PageLead
                text={<>Request an Appointment</>}
                subtext={<>If this is a medical emergency please contact us instead of completing this form <br /><a href="tel:541-889-7776">(541) 889-7776</a></>}
                subTextStyle="danger"
            />
            <Container className="max-width-sm mb-5">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <Form.Group className="form-input">
                                <Form.Label>Owner Full Name<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter owner full name"
                                    {...register("full_name")}
                                />
                                {errors.full_name && <span className="form-input-error text-danger">{errors.full_name.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-input">
                                <Form.Label>Email Address<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="john.doe@example.com"
                                    {...register("email")}
                                />
                                {errors.email && <span className="form-input-error text-danger">{errors.email.message}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-input">
                                <Form.Label>Home Phone<span className="text-danger"> *</span></Form.Label>
                                <Form.Control type="hidden" {...register("phone")} />
                                <Form.Control
                                    type="text"
                                    placeholder="(999) 999-9999"
                                    name="phone"
                                    ref={phoneField}
                                    onChange={onPhoneChange}
                                />
                                {errors.phone && <span className="form-input-error text-danger">{errors.phone.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-input">
                                <Form.Label>Pet's Name<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter pet's name"
                                    {...register("pet_name")}
                                />
                                {errors.pet_name && <span className="form-input-error text-danger">{errors.pet_name.message}</span>}
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-input mb-3" controlId="species">
                                <Form.Label>Species<span className="text-danger"> *</span></Form.Label>
                                <Form.Select aria-label="Default select example" size="lg" {...register("pet_species")}>
                                    <option value=""></option>
                                    <option value="Dog" id="dog">Dog</option>
                                    <option value="Cat" id="cat">Cat</option>
                                    <option value="Horse" id="other">Horse</option>
                                    <option value="Other" id="other">Other</option>
                                </Form.Select>
                                {errors.pet_species && <span className="form-input-error text-danger">{errors.pet_species.message}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-input">
                                <Form.Label>Breed<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter pet's breed"
                                    {...register("pet_breed")}
                                />
                                {errors.pet_breed && <span className="form-input-error text-danger">{errors.pet_breed.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="form-input mb-3" controlId="appointment-type">
                                <Form.Label>Type of Appointment<span className="text-danger"> *</span></Form.Label>
                                <Form.Select aria-label="Default select example" size="lg" {...register("appointment_type")}>
                                    <option value=""></option>
                                    <option value="Annual Physical Exam" id="annual">Annual Physical Exam</option>
                                    <option value="Vaccinations" id="vaccinations">Vaccinations</option>
                                    <option value="Sick or Injured" id="sick-injured">Sickness / Injury</option>
                                    <option value="New Patient" id="new-patient">New Patient Checkup</option>
                                    <option value="Technician Appointment" id="technician">Technician Appointment (ex. Nail Trim, Anal Gland Expression, etc...)</option>
                                    <option value="Prescription Refill" id="rx-refill">Prescription Refill</option>
                                    <option value="Other" id="rx-refill">Other (Please specify in notes)</option>
                                </Form.Select>
                                {errors.appointment_type && <span className="form-input-error text-danger">{errors.appointment_type.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-input mb-3">
                                <Form.Label>Comments / Reason for Visit<span className="text-danger"> *</span></Form.Label>
                                <Form.Control  as="textarea" rows={5}
                                    type="text"
                                    {...register("message")}
                                />
                                {errors.message && <span className="form-input-error text-danger">{errors.message.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-input mb-3" controlId="preferredDay">
                                <Form.Label>Preferred Day(s)<span className="text-danger"> *</span></Form.Label>
                                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((day, index) => (
                                    <Form.Check 
                                        name={`preferredDay.${day}`}
                                        type="checkbox" 
                                        id={day}
                                        value={day}
                                        label={day}
                                        {...register("preferred_day")}
                                        key={index}
                                           
                                    />
                                ))}
                                {errors.preferred_day && <span className="form-input-error text-danger">{errors.preferred_day.message}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-input mb-3" controlId="preferred-time">
                                <Form.Label>Preferred Time(s)<span className="text-danger"> *</span></Form.Label>
                                {["8:00am - 10:00am", "10:00am - 12:00pm", "12:00pm - 2:00pm", "2:00pm - 4:00pm", "4:00pm - 5:30pm"].map((day, index) => (
                                    <Form.Check 
                                        type="checkbox" 
                                        id={day}
                                        value={day}
                                        label={day}
                                        {...register("preferred_time")}
                                        key={index}
                                           
                                    />
                                ))}
                                {errors.preferred_time && <span className="form-input-error text-danger"><br />{errors.preferred_time.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            {...register("recaptchaToken")}
                            value={recaptchaToken}
                        />
                        <div className="mb-3">
                            <ReCAPTCHA
                                sitekey={reCaptchaSiteKey}
                                onChange={handleToken}
                                onExpired={handleTokenExpired}
                                ref={recaptchaRef}
                            />
                        </div>
                    </Form.Group>
                    <div className="disclaimer">
                            * Required <br />
                            ** {organization.display_name} never sells or shares your information
                    </div>
                    <Button type="submit" disabled={submitIsDisabled}>
                            SUBMIT
                    </Button>
                </Form>
            </Container>
        </Container>
    );
};

export default RequestAppointment;