import React, { useContext } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

import "./scss/snackbar_toast.scss";

import { AppContext } from "src/context/app_context";

const SnackbarToast = (props) => {
    const { toast, message, onClose } = props;

    const { organization, isLoading, error } = useContext(AppContext);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error loading organization data.</div>;
    }
    if (!organization) {
        return null;
    }

    return (
        <ToastContainer containerPosition="fixed" position="middle-center">
            <Toast 
                show={true} 
                onClose={onClose} 
                animation={true} 
                className={toast.theme} 
                delay={4500} 
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">{organization.display_name}</strong>
                </Toast.Header>
                <Toast.Body><i className={toast.icon} />{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default SnackbarToast;