import React from "react";
import { Container } from "react-bootstrap";

import MainHero from "../main/main_hero";
import EveryFAQ from "../global/every_faq";

import imgCareCreditLogo from "../../assets/images/logoCareCredit.webp";

import "./scss/carecredit.scss";

const CareCredit = () => {

    return (
        <>
            <MainHero
                title="CareCredit"
                subtitle="Financial Flexibility for Your Pet's Veterinary Needs"
                text={<>
                    We're committed to making veterinary care accessible to all pet owners, and we're proud to partner with CareCredit to provide you with this valuable financial tool.
                </>}
            />

            <div className="spacer wave-top"></div>

            <Container className="max-width-lg mb-4">
                <a href="https://www.carecredit.com/vetmed/" target="_blank" rel="noreferrer"><img src={imgCareCreditLogo} alt="CareCredit" className="carecredit-logo-image" /></a>
            </Container>

            <Container className="max-width-xl">                
                <EveryFAQ data="careCreditData" columns={2} />
            </Container>
        </>
    );
};

export default CareCredit;