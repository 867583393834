import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";

import "./scss/every_midpagehero.scss"; 


/**
 * EveryMidPageHero is a responsive two column solid color hero with an image, text, and a call to action button.
 */

const EveryMidPageHero = (props) => {
    let { title, subtitle, text, button, image } = props;
    return (
        <Container fluid className="py-5 every-midpagehero-container">
            <Container>
                <Row className="d-flex align-items-center">
                    {image && <Col className="every-midpagehero-img-container" md="4">
                        <img src={image} alt="" />
                    </Col>}
                    <Col md={image ? "8" : {offset: 2}}>
                        {title && <h1>{title}</h1>}
                        {subtitle && <h3 style={{color: "white"}}>{subtitle}</h3>}
                        {text && <p style={{color: "white"}}>{text}</p>}
                    </Col>
                    {button && <Col md={{ span: 8, offset: 4 }}><Button style={{width: "fit-content"}} href="/about-us">Meet the Team</Button></Col>}
                        
                </Row> 
            </Container>
        </Container>
    );
    
};

export default EveryMidPageHero;