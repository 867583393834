import { createContext, useCallback, useEffect, useState } from "react";
import useStorageService from "src/service/storage_service";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const [organization, setOrganization] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const storageSvc = useStorageService(setOrganization);

    // Callback function using useCallback to prevent re-renders:
    const handleGetOrganizationStorage = useCallback(() => {
        storageSvc.getOrganizationStorage()
            .catch(error => {
                setError(error);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        handleGetOrganizationStorage(); // Call the memoized function
    }, [handleGetOrganizationStorage]); // Dependency is now the memoized function

    return (
        <AppContext.Provider value={{ organization, isLoading, error }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;