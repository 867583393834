const useUtilityService = () => {
    const sortOfficeHours = (officeHours) => {
        // Define the order of days in a week
        const daysOfWeekOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        return daysOfWeekOrder
            .filter(day => officeHours.hasOwnProperty(day))
            .map(day => ({ day, hours: officeHours[day] }));
    };

    return {
        sortOfficeHours
    };
};

export default useUtilityService;