import React from "react";

import { Container } from "react-bootstrap";
import MainHero from "../main/main_hero";
import PageCard from "../global/page_card";
import serviceCardsData from "../../util/staticData/serviceCards.data";
import "../../assets/css/_services.scss";


const FleaAndTick = () => {
    let {heroText, subtitle, title} = serviceCardsData["fleaAndTick"];
    
    return (
        <>
            <MainHero
                title={title}
                subtitle={subtitle}
                text={heroText}
            />

            <div className="spacer wave-top"></div>

            <Container className="max-width-xl mb-5">
                <PageCard data="fleaAndTick" />
            </Container>
        </>
    );
};

export default FleaAndTick;
