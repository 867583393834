import { useCallback } from "react";
import useApi from "src/hooks/use_api";
import apiURI from "src/enum/api_uri";
import method from "src/enum/method";

const ORGANIZATION_STORAGE_KEY = "everydvm_organization";
const ORGANIZATION_EXPIRY_KEY = "everydvm_organization_expiry";
const ORGANIZATION_EXPIRY_TIME = parseInt(process.env.REACT_APP_LOCAL_STORAGE_EXPIRY, 10);
const EVERYDVM_TOKEN = "everydvm_token";

const useStorageService = (setOrganizationFromContext) => {
    const api = useApi();

    const getOrganizationStorage = useCallback(async () => {
        try {
            // 1. Check if organization is in local storage
            const storageOrganization = localStorage.getItem(ORGANIZATION_STORAGE_KEY);
            const storageExpiry = localStorage.getItem(ORGANIZATION_EXPIRY_KEY);

            if (storageOrganization && storageExpiry && new Date().getTime() < new Date(storageExpiry).getTime()) {
                const parsedData = JSON.parse(storageOrganization);
                setOrganizationFromContext(parsedData);
                return parsedData;
            }

            // 2. If not in storage, fetch from API
            const clientData = {
                grant_type: "client_credentials",
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            };

            const authRes = await api.request({
                method: method.POST,
                uri: apiURI.AUTH,
                route: "/v1/auth/login",
                data: clientData
            });

            if (authRes) {
                const { organization_id, token } = authRes.data;

                const orgRes = await api.request({
                    method: method.GET,
                    uri: apiURI.AUTH,
                    route: `/v1/organizations/${organization_id}`,
                    token: token
                });

                if (orgRes && !orgRes.error) {
                    setOrganizationFromContext(orgRes.data);
                    const now = new Date();
                    localStorage.setItem(ORGANIZATION_STORAGE_KEY, JSON.stringify(orgRes.data));
                    localStorage.setItem(ORGANIZATION_EXPIRY_KEY, new Date(now.getTime() + ORGANIZATION_EXPIRY_TIME).toISOString());
                    localStorage.setItem(EVERYDVM_TOKEN, token);
                    return orgRes.data;
                }
                throw new Error(orgRes.message);
            }
            throw new Error(authRes.message);
        } catch (error) {
            console.error("Error fetching/retrieving organization data:", error);
            return null;
        }
    }, [api, setOrganizationFromContext]);

    const setOrganizationStorage = (organizationData) => {
        localStorage.setItem(ORGANIZATION_STORAGE_KEY, JSON.stringify(organizationData));

        const now = new Date();
        localStorage.setItem(ORGANIZATION_EXPIRY_KEY, new Date(now.getTime() + ORGANIZATION_EXPIRY_TIME).toISOString());
    };

    const clearOrganizationStorage = () => {
        localStorage.removeItem(ORGANIZATION_STORAGE_KEY);
        localStorage.removeItem(ORGANIZATION_EXPIRY_KEY);
        localStorage.removeItem(EVERYDVM_TOKEN);
    };

    const refreshOrganizationData = async () => {
        const organizationData = await getOrganizationStorage();
        setOrganizationStorage(organizationData);
        return organizationData;
    };

    return {
        getOrganizationStorage,
        setOrganizationStorage,
        clearOrganizationStorage,
        refreshOrganizationData
    };
};

export default useStorageService;