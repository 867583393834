//import iconAltMedicine from "../../assets/images/icons/iconAltMedicine.svg";
//import iconDental from "../../assets/images/icons/iconDental.svg";
//import iconEmergency from "../../assets/images/icons/iconEmergency.svg";
import iconEOL from "../../assets/images/icons/iconEndOfLife.svg";
import iconMicrochip from "../../assets/images/icons/iconMicrochip.svg";
//import iconPharmacy from "../../assets/images/icons/iconPharmacy.svg";
//import iconPreventative from "../../assets/images/icons/iconVaccination.svg"; 
//import iconSpayNeuter from "../../assets/images/icons/iconSpayNeuter.svg"; 
//import iconSurgery from "../../assets/images/icons/iconSurgery.svg";
//import iconWellness from "../../assets/images/icons/iconWellness.svg";

import catHospital from "../../assets/images/icons/cat-hospital.svg";
import labReport from "../../assets/images/icons/lab-report.svg";
import pawHeart from "../../assets/images/icons/paw-heart.svg";
import dogNutrition from "../../assets/images/icons/iconDogNutrition.svg";
import dogWellness from "../../assets/images/icons/iconDogWellness.svg";
import iconDogObedience from "../../assets/images/icons/iconDogObedience.svg";
import iconVaccineCalendar from "../../assets/images/icons/iconCalendarWithPaw.svg";
import iconVaccination from "../../assets/images/icons/iconVaccination.svg";
import iconDentalEval from "../../assets/images/icons/iconDentalEval.svg";
import iconDentalXray from "../../assets/images/icons/iconDentalXray.svg";
import iconDentalTeeth from "../../assets/images/icons/iconDentalTeeth.svg";
import iconStethoscope from "../../assets/images/icons/iconStethoscope.svg";
import iconDogCat from "../../assets/images/icons/iconDogCatCross.svg";
import iconAnimalHospital from "../../assets/images/icons/iconPawHospital.svg";
import iconChart from "../../assets/images/icons/iconPetChart.svg";
import iconXray from "../../assets/images/icons/iconXray2.svg"; 
import iconMedicalBag from "../../assets/images/icons/iconMedicalBag.svg"; 
import iconMapPin from "../../assets/images/icons/iconMapPin.svg"; 
import iconTickControl from "../../assets/images/icons/iconTickControl.svg"; 



const cats = {
    heroText: "Cats have unique needs that can vary significantly from those of dogs, necessitating a specialized approach to ensure their well-being. Delivering optimal care for our feline companions involves adopting a comprehensive and individualized strategy tailored to their specific characteristics and health considerations. ",
    subtitle: "Cats Are Not Small Dogs",
    title: "Cats",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: catHospital,
            altText: "...",
            title: "Specialized Feline Care",
            text: "Dr. Robinson and her staff enjoy working with cats and we are pleased to have Dr. Hazel Carney, DVM, MS, DABVP, a feline specialist, available to see referral feline medicine and behavior cases."
        },
        {
            img: labReport,
            altText: "...",
            title: "I-131 Therapy",
            text: "Four Rivers has the area's only I-131 facility to treat hyperthyroid cats. I-131 therapy is considered a safe and effective long-term solution that provides a better quality of life for affected cats."
        },
        {
            img: pawHeart,
            altText: "...",
            title: "Worry-Free Care",
            text: "We know that bringing cats to the veterinarian can be a stressful event for both the cats and the owners. We are happy to give you tips on how to make a trip to the clinic a pleasant experience."
        }
    ]
};

const dogs = {
    heroText: "Dogs are known as man's best friends. They come in all different breeds, sizes and personalities. From that very cute puppy to your beloved senior canine, let Four Rivers Veterinary Clinic guide you through all the stages of their lives.",
    subtitle: "Dogs Really Are Man's Best Friends",
    title: "Dogs",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: dogWellness,
            altText: "...",
            title: "Comprehensive Wellness Care",
            text: "At Four Rivers Veterinary Clinic, we believe in proactive and comprehensive care for your canine family members. Our wellness plans are crafted to keep your dog healthy and happy throughout every stage of life. From routine vaccinations to regular check-ups, we provide a roadmap for preventive care, ensuring early detection of potential health issues and peace of mind."
        },
        {
            img: dogNutrition,
            altText: "...",
            title: "Nutrition",
            text: "Nutrition plays a crucial role in the overall health and well-being of your canine companions. We can provide expert nutritional advice tailored to the specific needs of your dog. Whether you're looking to address dietary concerns, manage weight, or support your dog's unique health conditions, we can offer guidance to ensure the dietary needs of your furry friend are being met."
        },
        {
            img: iconDogObedience,
            altText: "...",
            title: "Obedience Training",
            text: "We know that obedience training is an essential element in the bond between pets and their owners. Our team is dedicated to providing valuable dog obedience advice. From basic commands to behavior modification strategies, we offer personalized guidance to address specific challenges and encourage positive habits. Whether you're tackling puppy training or working with an adult dog, our expert guidance is designed to assist you in fostering a loving and well-behaved connection with your furry companion, turning every interaction into a source of joy for both you and your dog."
        }
    ]
};

const immunizations = {
    heroText: "Vaccinations play a crucial role in preventing the spread of contagious diseases and ensuring the well-being of both dogs and cats. From puppies and kittens to senior pets, we believe in the power of preventive care to safeguard your pets against potentially serious illnesses.",
    subtitle: "The Power of Preventative Care",
    title: "Immunizations",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconVaccineCalendar,
            altText: "...",
            title: "Regular Vaccines",
            text: "Regular vaccinations not only protect your pets, but also contribute to the well-being of the entire community by preventing the spread of contagious diseases. We will work closely with you to develop a vaccination schedule based on factors such as age, lifestyle, and overall health. We offer a range of vaccines to provide the highest level of immunity for your furry friends."
        },
        {
            img: iconVaccination,
            altText: "...",
            title: "Additional Canine Vaccines",
            text: <>
                <p>Four Rivers Veterinary Clinic offers additional vaccines (besides the core DA2P/PV, Rabies and Bordatella) for dogs whose lifestyle may expose them to other diseases.</p>
                <b>Merk Nobivac Canine Flu Bivalent</b>
                <p>Nobivac Canine Flu Bivalent is the first canine vaccine that aids in the control of disease associated with Canine Influenza Virus H3N8 and H3N2.Approved as an aid in the control of disease associated with canine influenza virus (CIV) H3N2 and H3N8 infection. Recommended for use in healthy dogs 7 weeks of age or older.
                </p>
                <b>Crotalus Atrox Toxoid (rattlesnake vaccine)</b>
                <p>The rattlesnake vaccine was developed to give protective antibodies to dogs who would be around the Western Diamond Rattlesnake. These antibodies help to lessen the pain of the bite and decrease the risk of permanent damage to the dog. Vaccinated dogs should still be examined by a veterinarian if bitten.</p>
            
                <p>The recommended protocol for this vaccine is an initial vaccination the first year with a booster immunization 4 weeks after. The maximum levels of antibodies develop 6 to 8 weeks after vaccination and duration of protection is approximately 6 months. In subsequent years dogs are given a single booster vaccination. If your dog will be in a part of the country where rattlesnakes will be active all year, we recommend a vaccination every 6 months.</p>
            
                <p>For more questions about this vaccine, please call Four Rivers Veterinary Clinic or visit the <a href="https://www.redrockbiologics.com/" target="_blank" rel="noreferrer">Red Rock Biologics</a> website.</p>
                
            </>
        }
    ]
};
const dental = {
    heroText: "Dental disease is one of the most overlooked health problems facing our pets today. Let Dr. Robinson and her staff flip those lips and evaluate your pet's dental health.",
    subtitle: "Comprehensive Dental Care for Your Pet",
    title: "Dental Care",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconDentalEval,
            altText: "...",
            title: "What's That Smell?",
            text: <>
                <p>Have you ever wondered what that smell coming from your pet's mouth might be? It could be a sign of underlying dental issues. Common symptoms of dental disease in animals include bad breath, inflamed gums, and difficulty eating. If left untreated, these issues can escalate, potentially leading to serious health problems such as heart and kidney damage.</p>
                <p>What are some of the signs of dental disease in dogs and cats?
                    <ul>
                        <li>Bad breath</li>
                        <li>Loose teeth or teeth that are discolored or covered in tartar</li>
                        <li>Your pet pulls away when you touch the mouth area</li>
                        <li>Drooling or dropping food from the mouth, or chewing on one side of the mouth</li>
                        <li>Bleeding from the mouth</li>
                        <li>Loss of appetite or loss of weight</li>
                    </ul>
                </p>
            </>
        },
        {
            img: iconDentalXray,
            altText: "...",
            title: "Professional Teeth Cleaning",
            text: <>
                <p>When dental disease is present your pet will benefit from a professional teeth cleaning visit. There are many steps to a professional cleaning. Under general anesthesia, your pet's teeth will be completely examined, scaled, and charted for damage and defects. The area under the gum line is also examined, roots are and the teeth are polished. Teeth that are abscessed or loose will be extracted, the sockets flushed and sutured closed. Antibiotics are often used to help treat gingival infections but also to protect the pet's blood and organs. We then schedule a courtesy follow-up examination in 7 to 10 days to be sure your pet's mouth has healed well after the cleaning and that any questions about follow-up home dental care are answered.</p>

                <b>Dental Radiographs</b>
                <p> We are excited to now be able to offer digital dental radiographs of your pet's teeth taken during dental treatment sessions. Our new unit the Midmark VetPro DC allows us to take images of teeth and their roots so we can examine what is going on below the gumline as well as above. We can now look for tooth root abscesses, root resorption and possible bone pathology as well.</p>
            </>
        },
        {
            img: iconDentalTeeth,
            altText: "...",
            title: "Home Care",
            text: <>
                <p>The mainstay of at home dental care has always been brushing your pet's teeth. But not all toothpastes are the same. There are many available brands of pet toothpaste. The most important thing to remember is that pet toothpaste does not foam like our own toothpaste, so please do not use yours for your pet. There is also a difference in fluoride levels between human and animal toothpastes so please be sure to use pet toothpaste.



                </p>
                <p>In addition to brushing teeth there are dental chews available for your pet. We currently recommend and sell Ora-vet chews made by Boehringer Ingelheim. As part of our dental specials we offer a free 14 count box of these chews. Hills Science Diet has also developed a dental chew and there are wipes and sprays available on the markets well.</p>
                <p>But remember ... brushing is best!</p>
            </>
        }
    ]
};

const surgical = {
    heroText: "No surgery is considered 'simple', so we offer pre-surgical blood work to evaluate parameters like blood count, liver and kidney values, and electrolytes, in combination with a complete physical examination to determine if your pet is ready for surgery and what steps need to be taken to ensure good surgical outcomes.",
    subtitle: "There is No Such Thing as a Simple Surgery",
    title: "Surgical Services",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconDogCat,
            altText: "...",
            title: "Special Training in Bone Plate Applications",
            text: "In addition to soft tissue procedures like spays, neuters, and tumor removals, Dr. Robinson has had extra training in bone plate application for treatment of long bone fractures. Four Rivers brought in Veterinary Orthopedic Implants equipment, including a new drill and complete bone plate setup, which allows for 2.4mm and 3.5mm bone plates with locking screw and plate technology. This provides a more stable repair."
        },
        {
            img: iconAnimalHospital,
            altText: "...",
            title: "Comprehensive Surgical Care",
            text: <>
                <p>Surgical procedures can be a time of worry for pet owners. Four Rivers Veterinary Clinic does all they can to minimize that worry:
                    <ul>
                        <li>A complete physical exam is performed on surgical patients prior to their anesthesia.</li>
                        <li>An anesthetic plan is tailored to fit each animal's individual needs.</li>
                        <li>Appropriate pain medications to the type of surgery being performed are provided and we send additional pain medications home as well to ensure the animals have a comfortable recovery.</li>
                        <li>Pets have IV catheters, IV fluids when indicated, and heat sources to maintain body temperature are provided while in surgery.</li>
                        <li>Animals are monitored by veterinary assistants, as well as being continuously monitored by an anesthesia monitor which monitors such things as heart rate, lead II EKG, oxygen saturation, end tidal carbon dioxide, body temperature and respiration rate.</li>
                        <li>Pets are monitored during recovery and wake up on padded beds with heat sources.</li>
                    </ul>
                </p>
            </>
        },
        {
            img: iconStethoscope,
            altText: "...",
            title: "Surgical Specialists",
            text: "If there is a surgery that Dr. Robinson feels would be best performed by a specialist, she will be happy to help get you set up with a surgical specialist. West Vet Specialty Center in Boise Idaho has three board certified surgeons who can perform those specialized surgeries."
        }
    ]
};

const radiology = {
    heroText: "Radiology (x-ray) is a non-invasive tool that can be essential for veterinarians as we try to determine causes of illness and a good treatment plan for your pet. X-rays are used to determine health problems in many different organ systems. In addition to the obvious broken bones or ingested foreign bodies, x-rays can help us determine problems within the heart and lungs, as well as the urinary tract, and even look for tooth root abscesses in the mouth.",
    subtitle: "Clarity in Care",
    title: "Radiology",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconChart,
            altText: "...",
            title: "Digital Technology",
            text: "Here at Four Rivers Veterinary Clinic, we have a Vet-Ray DR system and also utilize digital dental radiology technology with the Midmark Vet-Pro DC. We use Ezyvet software and a PAC system (Asteris Keystone) so we're able to keep all radiogrpahs in the pet's account for easy reference."
        },
        {
            img: iconXray,
            altText: "...",
            title: "A Variety of Services",
            text: <>
                <p>
            Our clinic offers plain and some contrast radiology services. We are pleased to offer digital radiography through our Regius II Konica Minolta system. With this new system we are able to take top quality radiographs with less exposure to your pet. If there are any concerns regarding findings on the radiographs they are sent electronically to the board certified radiologists at WestVet Specialty Clinic for further evaluation.
                </p>
                <p>
            For those animals who need advanced imaging such as CT or MRI, we will refer you to <a href="https://www.westvet.net/" target="_blank" rel="noreferrer">West Vet Specialty Clinic</a> in Boise, Idaho.
                </p>
            </>
        }
    ]
};
const wellness = {
    heroText: "At Four Rivers Veterinary Clinic, we prioritize the well-being of your furry family members. Our commitment to preventive care begins with regular wellness exams, essential for maintaining your pet's health and happiness.",
    subtitle: "Healthy Pets, Happy Lives",
    title: "Wellness Examinations",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconMedicalBag,
            altText: "...",
            title: "Wellness Packages",
            text: "In today's economy, it can be more difficult for owners to provide all the care their pets may need. Four Rivers Veterinary Clinic is in the development phase of Wellness packages. These packages will be designed to give owners individual plan options that will provide desired health care in monthly payment plans through automatic withdrawals."
        }
    ]
};
const fleaAndTick = {
    heroText: "At Four Rivers Veterinary Clinic we want our clients to be fully informed about external parasites in the Treasure Valley.",
    subtitle: "Bite-Free Bliss",
    title: "Flea and Tick Control",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconMapPin,
            altText: "...",
            title: "Local Area Update",
            text: "We have seen an increasing number of pets with both fleas and ticks in the past few years. This is a change, and many pet owners don’t believe we have fleas here in Malheur and Payette county. Because the numbers of parasites have increased and because both fleas and ticks can transmit parasites and serious diseases we are recommending flea and tick control for all cats and dogs that continues all year round."
              
        },
        {
            img: iconTickControl,
            altText: "...",
            title: "Keeping Things Under Control",
            text: <>
                <p>
            How to establish adequate flea control is another area that is often misunderstood. That is because the flea life cycle is hard to understand. Essentially a good flea control product should kill not only adult fleas but also kill both eggs and immature flea life stages. Good flea control also means the pet’s environment must be controlled. To that end we frequently encourage owners to use flea bombs that contain the insect growth regulator Nylar in their homes at the same time flea control for all pets in the household is started. Some owners also go ahead and use yard sprays or pest control services to treat their yards for fleas and ticks.
                </p>
                <p>
            Here at Four Rivers Veterinary Clinic we recommend two products for dogs, one topical Frontline Gold or the oral chew Nexguard. Both of these products are labeled to kill both fleas and ticks. For cats we recommend the topical product Frontline Gold. Frontline Gold is the improved version of Frontline Plus, an extra insect growth regulator was added to provide longer control over the egg and larval stages of the flea life cycle. All of these products are to be used once a month and we recommend they be used all year. As a rule we do not usually recommend flea shampoos or flea collars. The only exception is the Seresto brand collars, these collars which are available for both cats and dogs. These collars provide flea and tick control for up to 8 months. We do not carry these collars but can be found at stores like D & B.
                </p>
                <p>
            Please call us with any flea and tick control questions.
                </p>
            </>
        }
    ]
};
const microchipping = {
    heroText: "Microchipping is a professional way to identify your pet. It's a permanent identification and its proven safe and effective. At Four Rivers Vet we use AKC Reunite microchips.",
    subtitle: "Ensuring Pet Security",
    title: "Microchipping",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconMicrochip,
            altText: "...",
            title: "Safe Return Assurance",
            text: "Microchipping your pets is a simple yet powerful step towards ensuring their safety and well-being. The tiny, painless microchip is inserted under your pet's skin and contains a unique identification number. This number, when registered in a national database, allows for quick and efficient identification, helping to reunite lost pets with their families. Rest easy knowing your beloved companions have a secure way to find their way back home."
        }
    ]
};
const eol = {
    heroText: "When treasured family pets near the end of their lives, owners are often unsure what the next right steps might be. One of our duties at Four Rivers Veterinary Clinic is to help you determine when that time for euthanasia has come.",
    subtitle: "Compassionate Care",
    title: "Hospice and Euthanasia Services",
    imgLocation: "left",
    imgSize: "small",
    cardBorder: false,
    cardSeparator: true,
    data: [
        {
            img: iconEOL,
            altText: "...",
            title: "Doing What's Best for Your Pet",
            text: <>
                <p>
                Many health conditions that pets experience as they age can require long-term medication and can sometimes be painful for the pet. In some cases, only palliative care in the form of pain medications is their only option. Other times, there are supportive therapies that can help keep them comfortable.
                </p>
                <p>
                When it does come time to have a beloved pet euthanized, the staff at Four Rivers Veterinary Clinic are there to give you many caring options. Dr. Robinson and her staff are able to perform this service in the clinic, but they can sometimes do house calls so that owners may have this service performed where the pet is more comfortable.
                </p>
                <p>
                Four Rivers does not have a burial service available but we do offer cremation services provided by <a href="https://www.adaanimalcrematorium.com/" target="_blank" rel="noreferrer">Ada Animal Crematorium</a> in Boise, Idaho. They have provided respectful caring cremation services to the area for many years.
                </p>
            </>
        }
    ]
};

const serviceCardsData = {
    cats,
    dogs,
    immunizations,
    dental,
    surgical,
    radiology,
    wellness,
    fleaAndTick,
    microchipping,
    eol
};

export default serviceCardsData;