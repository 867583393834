import imgHero04 from "../../assets/images/imageHero04.webp";
import faqCard from "../../assets/images/QA_card_light_2.png";
import calendarCard from "../../assets/images/calendar_card.png";
import pharmacyCard from "../../assets/images/pharmacy_card.png";
// import resoucesCard from "../../assets/images/resources_card.png";
import servicesDogs from "../../assets/images/services_dogs.webp";
import servicesCats from "../../assets/images/services_cats.webp";
import servicesImmunizations from "../../assets/images/services_immunizations.webp";
import servicesDental from "../../assets/images/services_dental.webp";
import servicesSurgery from "../../assets/images/services_surgery.webp";
import servicesRadiology from "../../assets/images/services_radiology.webp";
import servicesWellness from "../../assets/images/services_wellness.webp";
import servicesFleaTick from "../../assets/images/services_pest.webp";
import servicesEOL from "../../assets/images/services_eol.webp";
import servicesMicrochipping from "../../assets/images/services_microchip.webp";


const homepage = {
    imgLocation: "top",
    hrVisible: false,
    linkCard: true,
    data: [
        {
            img: calendarCard,
            altText: "...",
            title: "Book Appointment",
            link: "/request-appointment"
        },
        {
            img: pharmacyCard,
            altText: "...",
            title: "Online Pharmacy",
            link: "https://fourriversveterinaryclinic2.securevetsource.com/site/view/site/view/HomeDelivery.pml?retUrl=https://www.4riversvet.com/",
            target: "_blank",
            rel: "noopener noreferrer"

        },
        // {
        //     img: resoucesCard,
        //     altText: "...",
        //     title: "Resources",
        //     link: "/resources"
        // },
        {
            img: faqCard,
            altText: "...",
            title: "FAQs",
            link: "/faqs"
        }
    ]
};

const forms = {
    imgLocation: "top",
    hrVisible: true,
    data: [
        {
            img: imgHero04,
            altText: "...",
            title: "New Patient Form",
            buttonText: "New Patient Form",
            text: "Tell us about you and your pet.",
            link: "/new-patient"
        },
        {
            // img: imgHero05,
            altText: "...",
            title: "Request Appointment",
            buttonText: "Request Appointment",
            text: "Request an appointment.",
            link: "/request-appointment"
        }
    ]
};

const services = [
    {
        imgLocation: "left",
        hrVisible: true,
        sectionHeader: "Animal Care",
        sectionDescription: "Dogs and cats, while both cherished companions, have distinct medical needs. From dietary preferences to susceptibility to certain illnesses, our veterinary care is tailored to address the individual needs of these two beloved species.",
        data: [
            {
                img: servicesCats,
                altText: "...",
                title: "Cats",
                buttonText: "Learn More",
                text: "From wellness exams to specialty services, we'll take excellent care of your furry friends.",
                link: "/services/cats"
            },
            {
                img: servicesDogs,
                altText: "...",
                title: "Dogs",
                buttonText: "Learn More",
                text: "From wellness exams to specialty services, we'll take excellent care of your furry friends.",
                link: "/services/dogs"
            }
        ]
    },
    {
        imgLocation: "left",
        hrVisible: true,
        sectionHeader: "Medical & Surgical Care",
        sectionDescription: "Four Rivers Veterinary Clinic is happy to offer complete medical and surgical care for your pet. We are a progressive clinic with the latest in technology and treatment options. Below is an example of our services for dogs, cats, and pocket pets.",
        data: [
            {
                img: servicesImmunizations,
                altText: "...",
                title: "Immunizations",
                buttonText: "Learn More",
                text: "We understand the unique needs of large animals, and are equipped to provide high-quality services.",
                link: "/services/immunizations"
            },
            {
                img: servicesDental,
                altText: "...",
                title: "Dental Care",
                buttonText: "Learn More",
                text: "Give your new puppy the best start in life with our comprehensive puppy package.",
                link: "/services/dental"
            },
            {
                img: servicesSurgery,
                altText: "...",
                title: "Surgical Services",
                buttonText: "Learn More",
                text: "Set your new kitten up for a lifetime of purrs and playfulness with our comprehensive kitten package.",
                link: "/services/surgical"
            },
            {
                img: servicesRadiology,
                altText: "...",
                title: "Radiology (X-Rays)",
                buttonText: "Learn More",
                text: "Set your new kitten up for a lifetime of purrs and playfulness with our comprehensive kitten package.",
                link: "/services/radiology"
            }
        ]
    },
    {
        imgLocation: "left",
        hrVisible: true,
        sectionHeader: "Wellness Care",
        sectionDescription: "In today's economy, it can be more difficult for owners to provide all the care their pets may need. Four Rivers Veterinary Clinic is in the development phase of Wellness packages. These packages will be designed to give owners individual plan options that will provide desired health care in monthly payment plans through automatic withdrawals.",
        data: [
            {
                img: servicesWellness,
                altText: "...",
                title: "Wellness Examinations",
                buttonText: "Learn More",
                text: "From wellness exams to specialty services, we'll take excellent care of your furry friends.",
                link: "/services/wellness"
            },
            {
                img: servicesFleaTick,
                altText: "...",
                title: "Flea & Tick Control",
                buttonText: "Learn More",
                text: "We fully grasp the bond between you and your horse and are dedicated to providing the care you expect.",
                link: "/services/flea-tick"
            },
            {
                img: servicesMicrochipping,
                altText: "...",
                title: "Microchipping",
                buttonText: "Learn More",
                text: "We fully grasp the bond between you and your horse and are dedicated to providing the care you expect.",
                link: "/services/microchipping"
            },
            {
                img: servicesEOL,
                altText: "...",
                title: "Hospice & Euthanasia",
                buttonText: "Learn More",
                text: "We fully grasp the bond between you and your horse and are dedicated to providing the care you expect.",
                link: "/services/end-of-life-care"
            }
        ]
    }
];

const pageCardsData = {
    homepage,
    forms,
    services
};

export default pageCardsData;