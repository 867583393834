import { Container, Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import EverySVG from "../global/every_svg";

import "./scss/main_stakes.scss";

const MainStakes = () => {
    const svgProps = {
        bgColor: "transparent",
        border: false,
        color: "secondary",
        padding: "p-0",
        size: "large"
        
    };
    
    return (
        <Container fluid className="main-stakes-cards-container pb-5">
            <Container className="max-width-xl">
                <Row>
                    <Col sm={6} lg={3} align="center">
                        <Link to="/services">
                            <EverySVG 
                                imageName="img-dental"
                                {...svgProps}
                                    
                            />
                            <h5>Dental</h5>
                        </Link>
                    </Col>
                    <Col sm={6} lg={3} align="center">
                        <Link to="/services">
                            <EverySVG 
                                imageName="img-radiology"
                                {...svgProps}
                                    
                            />
                            <h5>Radiology</h5>
                        </Link>
                    </Col>
                    <Col sm={6} lg={3} align="center">
                        <Link to="/services">
                            <EverySVG 
                                imageName="img-spay-neuter"
                                {...svgProps}
                            />
                            <h5>Spay and Neuter</h5>
                        </Link>
                    </Col>
                    <Col sm={6} lg={3} align="center">
                        <Link to="/services">
                            <EverySVG 
                                {...svgProps}
                                imageName="img-surgery"
                            />
                            <h5>Surgical</h5>
                        </Link>
                    </Col>
                </Row>
            </Container>                       
        </Container>
    );
};

export default MainStakes;