import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import MainHero from "../main/main_hero";
import PageCardLayered from "../global/page_card_layered";

import pageCardsData from "../../util/staticData/pageCards.data";

import "../../assets/css/_services.scss";

const Services = () => {

    const  services = pageCardsData.services;

    return (
        <>
            <MainHero
                title="Services"
                text={<>
                    Four Rivers Veterinary Clinic is happy to offer complete medical and surgical care for your pet. We are a progressive clinic with the latest in technology and treatment options. Below is an example of our services for dogs, cats, and pocket pets.
                </>}
            />

            <div className="spacer wave-top"></div>

            <div className="services-landing">

                <div>
                    {services.map(({ data, sectionHeader, sectionDescription, imgLocation, hrVisible }, index) => (
                        <Container fluid key={sectionHeader} className={`${index % 2 !== 0 ? "bg-color" : ""}`}>
                            <Container className="max-width-xl">
                                <Row className="py-5">
                                    <Col xs={12} sm={12} md={4} xl={5}>
                                        <h3>{sectionHeader}</h3>
                                        <p>{sectionDescription}</p>
                                    </Col>
                                    <Col>
                                        <PageCardLayered data={{data, imgLocation, hrVisible}} />
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    ))}
                </div>
            </div>
            
        </>
    );
};

export default Services;