/**
 * Get sizes and positions for page card formatting
 *
 * @param {Object} title vet/staff info
 * @returns {string} returns concatenated string with prefix, first_name, last_name, suffix
 */

const getRowPosition = (pageCardData) => {
    if (pageCardData.imgLocation === "top" && Object.keys(pageCardData.data).length < 3) {
        return "justify-content-md-center";
    }
    return "";
};

export const getFlexDirection = (location, size, index) => {
    switch (location) {
        case "alternate":
            if (index % 2 !== 0) {
                return "img-right flex-row-reverse";
            }
            return "img-left";
        case "right":
            return "img-right flex-row-reverse";
        case "left":
            if (size === "small") {
                return "img-left img-small";
            }
            return "img-left";
        default: 
            return `img-${location}`;
    }
};

export const getCardColSize = (pageCardData) => {
    switch (pageCardData.imgLocation) {
        case "top": 
            return 12;
        case "left": 
            if (pageCardData.imgSize === "small") {
                return {
                    img: { span: 2, offset: 1 },
                    text: 9
                };
            }
            return {
                img: 6,
                text: 6
            };
        default: 
            return 6;
    }
};

export const getPageCardPositions = (pageCardData) => {
    const cardColSize = getCardColSize(pageCardData);
    const rowPosition = getRowPosition(pageCardData);

    return {
        cardColSize,
        rowPosition
    };
};