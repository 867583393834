import React from "react";

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import pageCardsData from "../../util/staticData/pageCards.data";
import { getPageCardPositions, getFlexDirection } from "../../util/getPageCardPositions";
import serviceCardsData from "../../util/staticData/serviceCards.data";

import "./scss/page_card.scss";

const PageCard = (props) => {
    let pageCardData = pageCardsData[props.data] || serviceCardsData[props.data];

    const {cardColSize, rowPosition} = getPageCardPositions(pageCardData);

    

    return (
        <Container>
            <Row className={rowPosition}>
                {pageCardData.data.map((card, index) => {
                    return (
                        <Col 
                            className="mb-4"
                            md={pageCardData.imgLocation === "top" ? 6 : 12} 
                            lg={pageCardData.imgLocation === "top" ? 4 : 12} 
                            key={index}>
                            <Card className={!pageCardData.cardBorder ? "page-card border-none" : "page-card"}>
                                <Row className={getFlexDirection(pageCardData.imgLocation, pageCardData.imgSize || "", index)}>
                                    <Col sm={12} md={cardColSize.img} >
                                        <Card.Img src={card.img} alt={card.altText}/>
                                    </Col>
                                    <Col sm={12} md={cardColSize.text}>
                                        <Card.Body className={pageCardData.imgLocation === "top" && "page-card-text-container"}>
                                            <Card.Title>{card.title}</Card.Title>
                                            {pageCardData.hrVisible && <div className="page-card-title-divider"><hr /></div>}
                                            <Card.Text>{card.text}</Card.Text>
                                            {card.link && <Button variant="primary" href={card.link}>{card.buttonText || "Learn More"}</Button>}
                                        </Card.Body>
                                    </Col>
                                </Row>
                                
                            </Card>
                            {(pageCardData.cardSeparator && index !== pageCardData.data.length - 1) && <div className="page-card-separator">&nbsp;</div>}
                        </Col>
                    );
                })}
            </Row>
              
        </Container>
    );
};

export default PageCard;