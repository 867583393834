import React from "react";
import { Container } from "react-bootstrap";

import MainHero from "../main/main_hero";
import EveryFAQ from "../global/every_faq";

const FAQ = () => {

    return (
        <>
            <MainHero
                title="FAQs"
                subtitle="You've got questions. We've got answers!"
                text=""
            />

            <div className="spacer wave-top"></div>

            <Container className="max-width-xl">                
                <EveryFAQ data="FAQData" columns={2} />
            </Container>
        </>
    );
};

export default FAQ;