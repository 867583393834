import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";

import "src/components/App.scss";

import AppContextProvider from "src/context/app_context";
import ScrollToTop from "src/hooks/scroll_to_top";

import Router from "src/routes/sections";
import Header from "src/components/header/header";
import Footer from "src/components/footer/footer";
import SnackbarToast from "src/components/global/snackbar_toast";

function App() {

    const [snackType, setSnackType] = useState(null); 
    const [snackMessage, setSnackMessage] = useState(null);
    const [showSnackbarToast, setShowSnackbarToast] = useState(false);

    const showSnack = (type, message) => {
        setSnackType(type);
        setSnackMessage(message);
        setShowSnackbarToast(true);
    };

    const hideSnack = () => {
        setShowSnackbarToast(false);
    };

    return (
        <AppContextProvider>
            <BrowserRouter>
                <ScrollToTop>
                    <Header />
                    <Router showSnack={showSnack} />
                    { showSnackbarToast && 
                        <SnackbarToast 
                            toast={snackType} 
                            message={snackMessage}
                            onClose={hideSnack}
                        />
                    }
                    <Footer />
                </ScrollToTop>
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;