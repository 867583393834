const careCreditData = {
    data: [
        {
            question: "What is CareCredit?",
            answer: "CareCredit is a health and wellness credit card designed for your pet's health and wellness needs. It's a way to pay for the costs of many treatments and procedures and allows you to make convenient monthly payments.",
            sortOrder: 1
        },
        {
            question: "What can CareCredit be used for?",
            answer: "The CareCredit credit card can be used for routine veterinary appointments, emergency pet care, or a variety of surgeries and treatments your pet may need. It gives pet owners the peace of mind needed to care for pets big and small.",
            sortOrder: 1
        },
        {
            question: "How do I apply for CareCredit?",
            answer: "Apply at CareCredit.com/apply/ from any internet-connected device and see if you qualify. Or apply by phone at (800) 677-0718.",
            sortOrder: 2
        },
        {
            question: "Is CareCredit accepted by all veterinary clinics?",
            answer: "No. CareCredit is accepted at partner clinics that have registered to provide their clients these services, like Four Rivers Veterinary Clinic.",
            sortOrder: 3
        }
    ]
};

const FAQData = {
    data: [
        {
            question: "What should I do in case of a pet emergency?",
            answer: "In case of an emergency, call us immediately at (541) 889-7776. For emergencies during weekends and holidays, please contact West Vet at (208) 375-1600.",
            sortOrder: 1
        },
        {
            question: "Do you offer any specialized pet care services?",
            answer: "Yes, we offer specialized services including dental care, radiology, microchipping and hospice & euthanasia. Please call us for more information about these services.",
            sortOrder: 2
        },
        {
            question: "How often should my pet have a wellness exam?",
            answer: "We recommend annual wellness exams for most pets. Older pets or those with health issues may require more frequent check-ups. These exams are crucial for early detection and prevention of health problems.",
            sortOrder: 3
        },
        {
            question: "What types of animals do you treat?",
            answer: "We provide care primarily for dogs and cats.",
            sortOrder: 4
        },
        {
            question: "How can I make an appointment?",
            answer: "You can schedule an appointment by calling (541) 889-7776 or booking online through our website.",
            sortOrder: 5
        },
        {
            question: "When should I have my pet spayed or neutered?",
            answer: "The appropriate age for spaying or neutering can vary based on your pet's breed, size, and health. Generally, we recommend spaying or neutering dogs and cats between 4 to 6 months of age. This timing can help prevent certain health issues and unwanted behaviors. However, it's important to discuss your pet's specific needs with our veterinarians to determine the best timing for this procedure.",
            sortOrder: 6
        }
    ]
};

const faqData = {
    careCreditData,
    FAQData
};

export default faqData;