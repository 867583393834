import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import MainHero from "../main/main_hero";
import EverySubHero from "../global/every_subhero";
import EveryMidPageHero from "../global/every_midpagehero";
import EverySVG from "../global/every_svg";
import imageContactIcon from "../../assets/images/contact_icon.svg";

import maskPhoneOnChange from "../../util/maskPhoneOnChange";
import snackbarType from "../../enum/snackbar_type";
import templateType from "../../enum/email_template_type";
import FormsSchema from "../../schema/forms";

import useMessageService from "../../service/message_service";

import { AppContext } from "src/context/app_context";

const ContactUs = (props) => {

    let { showSnack } = props;
    const phoneField = useRef();
    const recaptchaRef = useRef();
    const messageService = useMessageService();

    const { organization } = useContext(AppContext);

    const { contactUsForm } = FormsSchema;

    const {
        clearErrors,
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(contactUsForm)
    });

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const onPhoneChange = async (input) => {
        let { value } = input.target;
        maskPhoneOnChange(input, clearErrors);
        setValue(input.target.name, value.replace(/\D/g, ""));
    };

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            visitorName: data.full_name,
            visitorEmail: data.email,
            visitorPhone: data.phone,
            visitorMessage: data.message,
            templateName: templateType.CONTACT_US,
            recaptchaToken
        };
            
        let res = await messageService.sendMessage(messageData);
        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, "Your message has been sent!");
        }
        
        setValue("full_name", null);
        setValue("email", null);
        setValue("phone", null);
        setValue("message", null);
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    return organization && (
        <>
            <MainHero 
                title={`Contact ${organization.display_name}`}
                subtitle="Questions or commments?"
                text={<>Have a question or comment? Please contact {organization.display_name} by completing our contact form below.</>}
            />

            <EverySubHero 
                title="In Case of Emergency"
                text={<>If this is a medical emergency please contact us before completing a form <br /><a href={`tel:${organization.main_phone}`}>{organization.main_phone}</a></>}
                spacerTop={true}
                bgColor="white"
            />

            <Container fluid className="secondary-page-header mt-5">
                <EveryMidPageHero 
                    title="We'd Love to Hear From You!" 
                    text="Drop us a line! Complete the form below and a member of our team will be in touch with you shortly."
                    image={imageContactIcon}
                />
            </Container>
            
            <Container fluid className="section-divider p-2 mb-5" />
            
    
            <Container className="my-5 max-width-xl">
                <Row className="form-row-reverse">
                    <Col xs={12} sm={12} lg={6}>
                        <Container className="max-width-sm mb-5">
                            
                            <Row className="mb-3">
                                <Col xs="auto" sm="auto">
                                    <EverySVG imageName="img-phone-icon" size="small" />
                                </Col>
                                <Col className="form-links">
                                    <h5>HOW</h5>
                                    <div className="mb-1"><a href={`tel:${organization.main_phone}`}>{organization.main_phone}</a></div>
                                    <div><a href={`mailto: ${organization.display_email}`}>{organization.display_email}</a></div>
                                    <div className="mb-1">Fill out the form &#8594; &#8594;</div>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="auto" sm="auto">
                                    <EverySVG imageName="img-clock-icon" size="small" />
                                </Col>
                                <Col>
                                    <h5>WHEN</h5>
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>Monday - Friday</Col>
                                        <Col>7:00am - 6:00pm</Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>Saturday</Col>
                                        <Col>8:00am - 12:00pm</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={4}>Sunday</Col>
                                        <Col>Closed</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="auto" sm="auto">
                                    <EverySVG imageName="img-map-icon" size="small" />
                                </Col>
                                <Col>
                                    <h5>WHERE</h5>
                                    <div>{organization.physical_address.address_line }</div>
                                    <div >{organization.physical_address.city}, {organization.physical_address.state} {organization.physical_address.postal_code}</div>
                                </Col>
                                
                            </Row>
                            <Row className="mb-3">
                                <Col className="form-contact-us-map">
                                    <iframe
                                        title="where"
                                        width="100%"
                                        height="350"
                                        style={{border: "0"}}
                                        loading="lazy"
                                        allowFullScreen
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API}&q=${organization.physical_address.google_map_address}`}>
                                    </iframe>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                        <Container className="max-width-sm mb-5">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="form-input">
                                    <Form.Label htmlFor="full_name">Full Name<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        id="full_name"
                                        type="text"
                                        {...register("full_name")}
                                    />
                                    {errors.full_name && <span className="form-input-error text-danger">{errors.full_name.message}</span>}
                                </Form.Group>
                                <Form.Group className="form-input">
                                    <Form.Label htmlFor="email">Email Address<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        id="email"
                                        type="text"
                                        {...register("email")}
                                    />
                                    {errors.email && <span className="form-input-error text-danger">{errors.email.message}</span>}
                                </Form.Group>
                                <Form.Group className="form-input">
                                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                                    <Form.Control type="hidden" {...register("phone")} />
                                    <Form.Control
                                        type="text"
                                        placeholder="(999) 999-9999"
                                        name="phone"
                                        ref={phoneField}
                                        onChange={onPhoneChange}
                                    />
                                </Form.Group>
                                <Form.Group className="form-input mb-3">
                                    <Form.Label htmlFor="message">Message Text</Form.Label>
                                    <Form.Control  as="textarea" rows={5} 
                                        id="message"
                                        type="text"
                                        {...register("message")}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="hidden"
                                        {...register("recaptchaToken")}
                                        value={recaptchaToken}
                                    />
                                    <div className="mb-3">
                                        <ReCAPTCHA
                                            sitekey={reCaptchaSiteKey}
                                            onChange={handleToken}
                                            onExpired={handleTokenExpired}
                                            ref={recaptchaRef}
                                        />
                                    </div>
                                </Form.Group>
                                <div className="disclaimer">
                                    * Required <br />
                                    {organization.display_name} never sells or shares your information
                                </div>
                                <Button variant="primary" type="submit" disabled={submitIsDisabled}>
                                    SUBMIT
                                </Button>
                            </Form>
                        </Container>
                    </Col>
                </Row>
            
                
            </Container>
        </>
    );
};

export default ContactUs;