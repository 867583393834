import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";

import imageMainHeroDog from "../../assets/images/hero-dogs.webp";
import imageMainHeroCat from "../../assets/images/hero-cats.webp";
import imageMainMobile from "../../assets/images/hero-mobile.webp";

import "./scss/main_hero.scss";


const MainHero = (props) => {
    let { title, subtitle, text, button } = props;

    return (
        <Container fluid className="main-hero-container">
            <Container>
                <Row>
                    <Col lg={3}><img className="hero-lg" src={imageMainHeroCat} alt="" /></Col>
                    <Col sm={12} lg={6}>
                        {subtitle && <h5>{subtitle}</h5>}
                        {title && <h1>{title}</h1>}
                        {text && <p>{text}</p>}
                        {button && <Button href={button.link}>{button.text}</Button>}
                        <img className="hero-sm" src={imageMainMobile} alt="" />
                    </Col>
                    <Col lg={3}>
                        <img className="hero-lg" src={imageMainHeroDog} alt="" />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default MainHero;