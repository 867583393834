import React, { useContext, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Form, Row, Col, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import googleAutoCompleteTypes from "../../enum/google_autocomplete_types";
import snackbarType from "../../enum/snackbar_type";
import templateType from "../../enum/email_template_type";

import maskPhoneOnChange from "../../util/maskPhoneOnChange";
import useTermsAndAgreements from "src/hooks/useTermsAndAgreements";

import FormsSchema from "../../schema/forms";

import useGooglePlaceAutoComplete from "../../util/google_place_autocomplete";
import useMessageService from "../../service/message_service";

import PageLead from "../global/page_lead";

import { AppContext } from "src/context/app_context";

import "./scss/forms.scss";

const NewPatient = (props) => {
    let { showSnack } = props;
    const recaptchaRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const messageService = useMessageService();

    const { organization } = useContext(AppContext);

    const locationRef = useRef();
    let addressAutoComplete = "";

    const { newPatientForm } = FormsSchema;

    const {
        clearErrors,
        register,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(newPatientForm)
    });

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const modalName = useRef(null);
    const modalText = useRef(null);

    const phoneField = useRef();
    const phoneMobileField = useRef();
    const phoneOtherField = useRef();

    const consentData = useTermsAndAgreements();

    const displayModal = (name, text) => {
        modalName.current = name;
        modalText.current = text;
        setModalShow(true);
    };

    const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const onAddressSelect = async () => {
        let placeObj = await googleAutoCompleteSvc.getPlace(addressAutoComplete);
        locationRef.current.value = placeObj.address_line_one;
        setValue("address_line_one", placeObj.address_line_one);
        setValue("city_state", `${placeObj.locality}, ${placeObj.adminArea1Long}`);
        setValue("postal_code", placeObj.postalCode);
        setFocus("address_line_two");
        clearErrors("address_line_one");
        clearErrors("city_state");
        clearErrors("postal_code");
    };

    const onAddressChange = () => {
        setValue("address_line_one", "");
        setValue("address_line_two", "");
        setValue("city_state", "");
        setValue("postal_code", "");
    };

    const onPhoneChange = async (input) => {
        let { value } = input.target;
        maskPhoneOnChange(input, clearErrors);
        setValue(input.target.name, value.replace(/\D/g, ""));
    };

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            ownerName: data.full_name,
            ownerNameOther: data.full_name_other,
            ownerEmail: data.email,
            ownerPhone: data.phone,
            ownerPhoneMobile: data.phone_mobile,
            ownerPhoneOther: data.phone_other,
            ownerAddressLineOne: data.address_line_one,
            ownerAddressLineTwo: data.address_line_two,
            ownerCityState: data.city_state,
            ownerPostalCode: data.postal_code,
            ownerContactMethod: data.contact_method,
            ownerReferralSource: data.referral_source,
            petName: data.pet_name,
            petBirthdate: data.pet_birthdate,
            petSpecies: data.pet_species,
            petGender: data.pet_gender,
            petSpayNeuter: data.spay_neuter,
            petBreed: data.pet_breed,
            petColor: data.pet_color,
            petMedications: data.pet_medications,
            petPreviousVet: data.previous_vet,
            petLastVaccinationDate: data.last_vaccination_date,
            consentAgree: data.consent_agree,
            consentSignature: data.consent_signature,
            consentDate: data.consent_date,
            templateName: templateType.NEW_PATIENT,
            recaptchaToken
        };

        let res = await messageService.sendMessage(messageData);
        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, "Your message has been sent!");
        }
        
        reset();
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const initGoogleService = async () => {
        addressAutoComplete = await googleAutoCompleteSvc.initAutoComplete(
            locationRef.current,
            onAddressSelect,
            googleAutoCompleteTypes.ADDRESS
        );
    };

    useEffect(() => {
        initGoogleService();
    }, []);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            if (errors.full_name || errors.email) {
                return;
            }
            if (errors.phone_other) {
                phoneOtherField.current.focus();
                return;
            }
            if (errors.phone_mobile) {
                phoneMobileField.current.focus();
                return;
            }
            if (errors.phone) {
                phoneField.current.focus();
                return;
            }
            if (errors.address_line_one) {
                locationRef.current.focus();
            }
        }
        
    });

    return organization && (
        <>
            <PageLead
                text={<>New Patient Form</>}
            />
            <Container>
                <div className="form-layout wide">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Owner Full Name<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter owner full name"
                                        {...register("full_name")}
                                    />
                                    {errors.full_name && <span className="form-input-error text-danger">{errors.full_name.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Co-Owner Full Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter co-owner full name"
                                        {...register("full_name_other")}
                                    />
                                    {errors.full_name_other && <span className="form-input-error text-danger">{errors.full_name_other.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Email Address<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="john.doe@example.com"
                                        {...register("email")}
                                    />
                                    {errors.email && <span className="form-input-error text-danger">{errors.email.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Home Phone<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control type="hidden" {...register("phone")} />
                                    <Form.Control
                                        type="text"
                                        placeholder="(999) 999-9999"
                                        name="phone"
                                        ref={phoneField}
                                        onChange={onPhoneChange}
                                    />
                                    {errors.phone && <span className="form-input-error text-danger">{errors.phone.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Cell Phone</Form.Label>
                                    <Form.Control type="hidden" {...register("phone_mobile")} />
                                    <Form.Control
                                        type="text"
                                        placeholder="(999) 999-9999"
                                        name="phone_mobile"
                                        ref={phoneMobileField}
                                        onChange={onPhoneChange}
                                    />
                                    {errors.phone_mobile && <span className="form-input-error text-danger">{errors.phone_mobile.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Co-Owner Phone</Form.Label>
                                    <Form.Control type="hidden" {...register("phone_other")} />
                                    <Form.Control
                                        type="text"
                                        placeholder="(999) 999-9999"
                                        name="phone_other"
                                        ref={phoneOtherField}
                                        onChange={onPhoneChange}
                                    />
                                    {errors.phone_other && <span className="form-input-error text-danger">{errors.phone_other.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="form-input">
                            <Form.Label>Address<span className="text-danger"> *</span></Form.Label>
                            <Form.Control type="hidden" {...register("address_line_one")} />
                            <Form.Control
                                type="text"
                                placeholder="Address Line One"
                                onChange={onAddressChange}
                                ref={locationRef}
                            />
                            {errors.address_line_one && <span className="form-input-error text-danger">{errors.address_line_one.message}</span>}
                        </Form.Group>


                        <Row>
                            <Form.Group className="form-input">
                                <Form.Control
                                    type="text"
                                    placeholder="Address Line Two"
                                    {...register("address_line_two")}
                                />
                                {errors.address_line_two && <span className="form-input-error text-danger">{errors.address_line_two.message}</span>}
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Control
                                        type="text"
                                        placeholder="City, State/Province"
                                        {...register("city_state")}
                                    />
                                    {errors.city_state && <span className="form-input-error text-danger">{errors.city_state.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Control
                                        type="text"
                                        placeholder="Zip Code"
                                        {...register("postal_code")}
                                    />
                                    {errors.postal_code && <span className="form-input-error text-danger">{errors.postal_code.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="form-input mb-3" controlId="contactMethod">
                                <Form.Label>Preferred Method of Communication<span className="text-danger"> *</span></Form.Label>
                                <Form.Check
                                    type="radio"
                                    id="home"
                                    value="Voice"
                                    label="Phone / Voice"
                                    {...register("contact_method")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="cell"
                                    value="Text"
                                    label="Cell / Text"
                                    {...register("contact_method")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="email"
                                    value="Email"
                                    label="Email"
                                    {...register("contact_method")}
                                />
                                {errors.contact_method && <span className="form-input-error text-danger">{errors.contact_method.message}</span>}
                            </Form.Group>
                        </Row>

                        <Form.Group className="form-input">
                            <Form.Label>How Did You Hear About Us?</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter referral source"
                                {...register("referral_source")}
                            />
                            {errors.referral_source && <span className="form-input-error text-danger">{errors.referral_source.message}</span>}
                        </Form.Group>
                        <div className="form-section-divider"><hr /></div>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="form-input">
                                    <Form.Label>Pet's Name<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter pet's name"
                                        {...register("pet_name")}
                                    />
                                    {errors.pet_name && <span className="form-input-error text-danger">{errors.pet_name.message}</span>}
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Group className="form-input mb-3">
                                    <Form.Label>Date of Birth<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register("pet_birthdate")}
                                    />
                                    {errors.pet_birthdate && <span className="form-input-error text-danger">{errors.pet_birthdate.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input mb-3" controlId="species">
                                    <Form.Label>Species<span className="text-danger"> *</span></Form.Label>
                                    {/* To make multiple columns for additional species, add columns around the Form.Check fields and use the "inline" parameter */}
                                    <Form.Check
                                        type="radio"
                                        id="dog"
                                        value="Dog"
                                        label="Dog"
                                        {...register("pet_species")}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="cat"
                                        value="Cat"
                                        label="Cat"
                                        {...register("pet_species")}
                                    />
                                    {errors.pet_species && <span className="form-input-error text-danger">{errors.pet_species.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input mb-3" controlId="gender">
                                    <Form.Label>Gender<span className="text-danger"> *</span></Form.Label>
                                    <Form.Check
                                        type="radio"
                                        id="male"
                                        value="Male"
                                        label="Male"
                                        {...register("pet_gender")}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="female"
                                        value="Female"
                                        label="Female"
                                        {...register("pet_gender")}
                                    />
                                    {errors.pet_gender && <span className="form-input-error text-danger">{errors.pet_gender.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input mb-3" controlId="spayNeuter">
                                    <Form.Label>Spayed/Nuetered<span className="text-danger"> *</span></Form.Label>
                                    <Form.Check
                                        type="radio"
                                        id="spayNeuterYes"
                                        value="Yes"
                                        label="Yes"
                                        {...register("spay_neuter")}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="spayNeuterNo"
                                        value="No"
                                        label="No"
                                        {...register("spay_neuter")}
                                    />
                                    {errors.spay_neuter && <span className="form-input-error text-danger">{errors.spay_neuter.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">
                                    <Form.Label>Breed<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter pet's breed"
                                        {...register("pet_breed")}
                                    />
                                    {errors.pet_breed && <span className="form-input-error text-danger">{errors.pet_breed.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter pet's color"
                                        {...register("pet_color")}
                                    />
                                    {errors.pet_color && <span className="form-input-error text-danger">{errors.pet_color.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="form-input">

                                    <Form.Label>Pet's Current Medications</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter pet's medications"
                                        {...register("pet_medications")}
                                    />
                                    {errors.pet_medications && <span className="form-input-error text-danger">{errors.pet_medications.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="form-input">

                                    <Form.Label>Previous Veterinarian (if applicable)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter previous vet"
                                        {...register("previous_vet")}
                                    />
                                    {errors.previous_vet && <span className="form-input-error text-danger">{errors.previous_vet.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input mb-3">
                                    <Form.Label>Date of Last Vaccination</Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register("last_vaccination_date")}
                                    />
                                    {errors.last_vaccination_date && <span className="form-input-error text-danger">{errors.last_vaccination_date.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {modalName.current}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="about-us-modal-body">
                                    <div className="about-us-modal-body-text">
                                        {modalText.current}
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Form.Group className="form-input mb-3" controlId="consentAgree">
                            <Form.Label><b>Consent for Treatment</b><span className="text-danger"> *</span></Form.Label>
                            <p>By checking the box below and signing this form, I hereby agree to the <Button className="form-button-link" style={{padding: "0"}} variant="link" onClick={() => displayModal(
                                consentData.title, consentData.text
                            )}>Terms of Treatment and Payment</Button>.</p>
                            
                            <Form.Check
                                type="radio"
                                id="consent"
                                label="I have read, understand, and agree to these conditions."
                                {...register("consent_agree")}
                            />
                            {errors.consent_agree && <span className="form-input-error text-danger">{errors.consent_agree.message}</span>}
                        </Form.Group>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="form-input mb-3">
                                    <Form.Label>Signature<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Sign here by typing your full name."
                                        {...register("consent_signature")}
                                    />
                                    {errors.consent_signature && <span className="form-input-error text-danger">{errors.consent_signature.message}</span>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-input mb-3">
                                    <Form.Label>Today's Date<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        type="date"
                                        {...register("consent_date")}
                                    />
                                    {errors.consent_date && <span className="form-input-error text-danger">{errors.consent_date.message}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                {...register("recaptchaToken")}
                                value={recaptchaToken}
                            />
                            <div className="mb-3">
                                <ReCAPTCHA
                                    sitekey={reCaptchaSiteKey}
                                    onChange={handleToken}
                                    onExpired={handleTokenExpired}
                                    ref={recaptchaRef}
                                />
                            </div>
                        </Form.Group>
                        <div className="disclaimer">
                            * Required <br />
                            ** {organization.display_name} never sells or shares your information
                        </div>
                        <Button type="submit" disabled={submitIsDisabled}>
                            SUBMIT
                        </Button>
                    </Form>
                </div>
            </Container>
        </>
    );
};

export default NewPatient;