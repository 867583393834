import React, { useContext } from "react";

import {Button, Container, Row, Col, NavDropdown} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

//import Logo from "../../assets/images/saved_from_old_site/logo_rounded.png";
import Logo from "../../assets/images/logo-4Rivers-white.webp";
import imgPhone from "../../assets/images/icons/phone.svg";
import imgEmail from "../../assets/images/icons/envelope.svg";

import { AppContext } from "src/context/app_context";

import "./header.scss";

const Header = () => {

    const { organization } = useContext(AppContext);

    return organization && (
        <>
            <Container fluid className="header-contact-info">
                <Row>
                    <Col sm={6}>
                        <h6><img src={imgPhone} alt="" /> <a href={`tel:${organization.main_phone_nbr}`}>{organization.main_phone_nbr}</a> </h6>
                    </Col>
                    <Col sm={6}>
                        <h6> <img src={imgEmail} alt="" /> <a href={`mailto: ${organization.display_email}`}>{organization.display_email}</a></h6>
                    </Col>
                </Row>
                 
            </Container>
            <Navbar expand="lg" data-bs-theme="dark" className="header-navbar">
                <Container>
                    <Navbar.Brand href="/"><img className="header-logo" src={Logo} alt={"logo"}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-end">
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <NavDropdown title="Resources" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/new-patient">New Patient Form</NavDropdown.Item>
                                <NavDropdown.Item href="/financing/carecredit">CareCredit</NavDropdown.Item>
                                <NavDropdown.Item href="/faqs">FAQs</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/services">Services</Nav.Link>
                            <Nav.Link href="/contact-us">Contact</Nav.Link>
                            <div>
                                <Button 
                                    variant="primary" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    href={organization.online_pharmacy_url} 
                                    className="header-button">Online Pharmacy</Button>
                            </div>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;