import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import pageCardsData from "../../util/staticData/pageCards.data";



import "../global/scss/page_card.scss";

const MainPageCards = () => {
    let pageCardData = pageCardsData["homepage"];
    return (
        <Container fluid className="main-stakes-cards-container my-5">
            <Container className="max-width-xl">
                <Container>
                    <Row className="py-4 text-center"><h2>We're Here to Help!</h2></Row>
                    <Row className="justify-content-md-center">
                        {pageCardData.data.map((card, index) => {
                            return (
                                <Col className="mb-4 d-flex justify-content-center" sm={6} md={4} lg={3} key={index}>
                                    <Link to={card.link} rel={card.rel} target={card.target}>
                                        <Card className="page-card-graphic-container">
                                            <Row>
                                                <Col sm={12} md={12} >
                                                    <Card.Img src={card.img} alt={card.altText}/>
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Card.Body>
                                                        <Card.Title>{card.title}</Card.Title>
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Link> 
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};

export default MainPageCards;