// import imgPlaceholderMale from "../../assets/images/about_us/imageTeamMemberMale.webp";
import imgPlaceholderFemale from "../../assets/images/about_us/imageTeamMemberFemale.webp";
import imgRobinsonErin from "../../assets/images/about_us/imgRobinsonErin.webp";
import imgCarneyHazel from "../../assets/images/about_us/imgCarneyHazel.webp";
import imgElliottBryn from "../../assets/images/about_us/imgElliottBryn.webp";
import imgWilliamsApril from "../../assets/images/about_us/imgWilliamsApril.webp";

const team = {
    data: [
        {
            id: "adfbe70ea62011eda8054422d5c03501",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgRobinsonErin,
            first_name: "Erin",
            last_name: "Robinson",
            prefix: "Dr.",
            suffix: "DVM",
            title: "",
            text: "Dr. Erin Robinson knew from a young age that she wanted to be a veterinarian. She began her college days at the University of Idaho where she obtained her Bachelors in Veterinary Science in 1992. She then attended Washington State University's College of Veterinary Medicine where she obtained her doctorate. Dr. Erin worked as an associate veterinarian in a mixed animal practice in Rupert, Idaho for three years before purchasing Four Rivers Veterinary Clinic and moving to Ontario, Oregon in 1999. She enjoys both surgery and medicine and has added interests in sight hounds and feline medicine. She has two children and they share their home with numerous dogs and cats. In her “free time” Dr. Erin enjoys cooking, reading and supporting her children in their latest adventures.",
            sortOrderOverride: 1
        },
        {
            id: "abfbe70ea62011eda8054422d5c03502",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgCarneyHazel,
            first_name: "Hazel",
            last_name: "Carney",
            prefix: "Dr.",
            suffix: "DVM, MS, DABVP",
            title: "",
            text: "Dr. Hazel Carney, DVM, MS, DABVP, is a graduate of Colorado State University where she received her Doctor of Veterinary Medicine degree in 1981. She also has a Master's degree in Physiology and Biophysics. In 1982, Dr. Carney opened the thirteenth registered cat-only practice in the U.S. where she was the senior clinician for thirteen years. In 1986 she became an adjunct professor of the Louisiana State University School of Veterinary Medicine and was a founding member of the Louisiana Veterinary Nuclear Medicine group which provided I-131 (radioactive iodine) therapy for veterinarians in Louisiana and Mississippi. Over the past ten years Dr. Carney has been instrumental in the continued development of the AAFP (American Association of Feline Practitioners) practice guidelines in the areas of behavior and nursing care. Dr. Carney is a Diplomat of the American Board of Veterinary Practitioners in Canine and Feline Clinical Practice. Because Dr. Carney limits her practice to cats, she has a very specialized scope of services for treating complex medical cases and feline behavior problems. She also shares her findings and expertise by speaking at various national and regional veterinary conferences.",
            sortOrderOverride: 2
        },
        {
            id: "abfbe70ea62011eda8054422d5c03503",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgElliottBryn,
            first_name: "Bryn",
            last_name: "Elliott",
            prefix: "",
            suffix: "",
            title: "Veterinary Assistant",
            text: "Bryn Elliott has worked part time at Four Rivers for about nine years now. When she is not assisting in the exam room or up front in reception, Bryn can be found out on the dog show circuit with either her own Australian Shepherds or handling others dogs, or on the ski slopes if there is snow. In addition to her numerous Aussies, Bryn is owned by two very precocious cats, 5 goats and numerous chickens.",
            sortOrderOverride: 3
        },
        {
            id: "abfbe70ea62011eda8054422d5c03504",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgWilliamsApril,
            first_name: "April",
            last_name: "Williams",
            prefix: "",
            suffix: "",
            title: "Veterinary Assistant",
            text: "While her original training was as a dental assistant, April Williams always preferred the animals. April has been employed by Four Rivers Veterinary Clinic since 2003. April began her employment in the kennels and soon discovered she wanted to expand her involvement in veterinary medicine. April assists in every part of the practice from up front duties in reception to the back in surgery and hospital care. If she could stay in one place in the clinic it would be in the lab performing diagnostic tests. April grew up with a variety of animals from horses to turtles, and assisted her father who trains hunting dogs. April is married and has two sons and a daughter. Her “fur kids” include a Jack Russell, a Jack Russell mix, and two cats. In her free time she enjoys camping, hunting, fishing, horseback riding and reading.",
            sortOrderOverride: 4
        },
        {
            id: "abfbe70ea62011eda8054422d5c03505",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgPlaceholderFemale,
            first_name: "Hailee",
            last_name: "Pearson",
            prefix: "",
            suffix: "",
            title: "Veterinary Assistant",
            text: "Bio text to come!",
            sortOrderOverride: 5
        }
    ]
};

const teamBioData = {
    team
};

export default teamBioData;