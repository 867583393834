import React from "react";

import { Button, Card, Col, Container, Row } from "react-bootstrap";

import "./scss/page_card.scss";

const PageCardLayered = (props) => {
    let pageCardData = props.data;

    const getRowPosition = () => {
        if (pageCardData.imgLocation === "top" && Object.keys(pageCardData.data).length < 3) {
            return "justify-content-md-center";
        }
        return "";
    };

    const getFlexDirection = (location, index) => {
        switch (location) {
            case "alternate":
                if (index % 2 !== 0) {
                    return "img-right flex-row-reverse";
                }
                return "img-left";
            case "right":
                return "img-right flex-row-reverse";
            case "left":
                return "img-left";
            default: 
                return `img-${location}`;
        }
    };
      
    return (
        
        <Container>
            <Row className={getRowPosition()}>
                {pageCardData.data.map((card, index) => {
                    return (
                        <Col 
                            className="mb-4 services-card-container"
                            md={pageCardData.imgLocation === "top" ? 6 : 6} 
                            lg={pageCardData.imgLocation === "top" ? 4 : 6} 
                            key={index}>
                            <div > 
                                <Card className="outer-card">
                                    <Card className="inner-card">
                                        <Row className={getFlexDirection(pageCardData.imgLocation, index)}>
                                            <Col >
                                                <Card.Body className={pageCardData.imgLocation === "top" && "page-card-text-container"}>
                                                    <Card.Title>{card.title}</Card.Title>
                                                    {pageCardData.hrVisible && <div className="page-card-title-divider"><hr /></div>}
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                        <Row className="services-card-body">
                                            <Col xs={6} sm={6} md={pageCardData.imgLocation === "top" ? 12 : 5}  style={{padding: "0 6px"}}>
                                                <Card.Img className="sevices-img" src={card.img} alt={card.altText}/>
                                            </Col>
                                            <Col className="services-card-button" style={{padding: "0 6px"}}>
                                                {card.link && <Button variant="primary" href={card.link}>{card.buttonText || "Learn More"}</Button>}
                                            </Col>
                                        </Row>
                                
                                    </Card>
                                </Card>

                            </div>
                            
                        </Col>
                    );
                })}
            </Row>
              
        </Container>
    );
};

export default PageCardLayered;