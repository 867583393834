// Main page loader
import React, { useContext } from "react";

import EveryMidPageHero from "../global/every_midpagehero";
import EverySubHero from "../global/every_subhero";
import MainHero from "./main_hero";
import MainPageCards from "./main_pagecards";
import MainStakes from "./main_stakes";

import imageMainHero from "../../assets/images/dog_cat_cursive_outline.svg";

import { AppContext } from "src/context/app_context";

const Main = (props) => {
    //let { showSnack } = props;

    const { organization, isLoading, error } = useContext(AppContext);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error loading organization data.</div>;
    }
    if (!organization) {
        return null;
    }

    return (
        <>
            <MainHero 
                title="We are a professional animal Veterinary Clinic offering medical and surgical care for small animals."
                subtitle="Professional Veterinary Care For Your Pet"
                text
                button={{link:"/request-appointment", text: "Book An Appointment"}}
            />
            <EverySubHero 
                title={<>Welcome to {organization.display_name}</>}
                text={<>We are a professional animal Veterinary Clinic offering medical and surgical care for small animals. Four Rivers has been operating in Ontario, Oregon since 1999, with an emphasis on preventative care and client education. We emphasize preventive medicine, wellness care, geriatric care, and dental care for our patients.</>}
                spacerTop={true}
                bgColor="white"
            />
            <MainStakes />
            <EveryMidPageHero 
                button={{link:"/request-appointment", text: "Book An Appointment"}} 
                subtitle="We're committed to giving your pets extraordinary care whenever and wherever they need it. Partner with one of our veterinarians today to begin proactively monitoring the health and wellness of the pets you love."
                image={imageMainHero}
            />
                
            <MainPageCards />
        </>
    );
};

export default Main;